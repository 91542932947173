.main {
  padding: 4rem;
  // height: 100%;
  height: 100vh;
  overflow: hidden;

  h1 {
    margin-top: 10vh;
  }

  .dot {
    color: #fff;
  }
}
