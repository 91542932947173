#work {
  padding: 4rem;
  height: 100%;
  min-height: 200vh;
  overflow: hidden;

  h1.lg-heading {
    margin-top: 30px;
  }

  h2.sm-heading {
    margin-top: 25px;
  }

  // Work/Projects
  .projects {
    margin-top: 30px;

    .card {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-right: auto;
      margin-left: auto;
      max-width: 350px;
      min-width: 350px;
      box-shadow: $secondary-color 0px 0px 15px 5px;
      padding: 5px;
      background: lighten($primary-color, 10);

      &-title {
        color: $secondary-color;
        font-size: 1.8rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: 0.9rem;
      }

      &-body {
        background: lighten($primary-color, 10);
        color: set-text-color($primary-color);
        border-bottom: 3px solid $secondary-color;
        padding-top: 10px;
      }

      &-text {
        text-align: center;
        font-weight: 500;
      }
      .project-description {
        min-height: 75px;
      }
      .project-technologies {
        .technology {
          color: $secondary-color;
          padding: 0 10px;
          .tech {
            width: 100%;
          }
        }
      }
    }

    img {
      width: 100%;
      min-height: 320px;
      max-height: 320px;
      cursor: pointer;
      border-radius: 0.3rem;
      @include easeIn;

      &:hover {
        opacity: 0.8;
        padding: 0.7rem 0.7rem;
        @include easeOut;
      }
    }
  }

  // Button Styles
  .btn {
    display: block;
    padding: 0.5rem 1rem;
    border: 0;
    // margin-bottom: 0.3rem;
    &:hover {
      background: $secondary-color;
      color: set-text-color($secondary-color);
    }
  }

  .btn-dark {
    @extend .btn;
    background: darken($primary-color, 50);
    color: #fff;
  }

  .btn-light {
    @extend .btn;
    background: lighten($primary-color, 50);
    color: #333;
  }
}

.project-card {
  opacity: 0;
}

@keyframes anim-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
