#about {
  padding: 2rem 4rem 4rem 4rem;
  height: 100%;
  overflow: hidden;
  margin: auto;
  max-width: 1100px;

  .secondary {
    color: $secondary-color;
  }

  h1.lg-heading {
    margin-top: 60px;
  }

  h2.sm-heading {
    margin-bottom: 25px;
  }

  h3.bio-heading {
    font-weight: 500;
    font-size: 40px;
    margin-bottom: 10px;
  }

  .about-info {
    display: block;

    .bio {
      font-size: 1.5rem;
    }

    .job {
      &-card {
        display: flex;
        min-height: 200px;
        max-width: 1100px;
        margin-bottom: 30px;
        background: lighten($primary-color, 5);
        border-bottom: $secondary-color 5px solid;
        border-top: $secondary-color 5px solid;
      }

      &-header {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        align-items: center;
        justify-content: center;
        min-width: 230px;
        background: darken($primary-color, 8);
      }

      &-content {
        flex: 3;
        display: flex;
        flex-wrap: wrap;
        border-left: $secondary-color 3px solid;
        overflow: hidden;
        padding-bottom: 10px;
        .job-points {
          line-height: 1.8;
          list-style: none;
          padding: 0 15px 0 0;
          margin-top: 5px;
          margin-bottom: 0;
          width: 100%;
          background: lighten($primary-color, 2);
          box-shadow: lighten($primary-color, 2) 0px 0px 15px 5px;
          li {
            padding-left: 2.8em;
            background: url("../../img/arrow.png") no-repeat left 5px;
            background-position: 15px 5px;
            background-size: 20px 20px;
          }
        }

        .job-technologies {
          text-align: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
          width: 100%;
          padding-top: 10px;
          .technology {
            background: lighten($primary-color, 2);
            box-shadow: lighten($primary-color, 2) 0px 0px 15px 5px;
            color: $secondary-color;
            font-size: 20px;
            font-weight: 500;
            padding: 0 10px;
          }
        }

        .job-description {
          padding: 0.5em 1em;
        }

        .job-item {
          width: 100%;
        }

        .job-subtitle {
          padding: 5px 10px;
        }
      }

      &-title {
        margin-bottom: 10px;
        font-weight: 500;
        width: 100%;
        text-align: center;
      }

      &-img-container {
        width: 100%;
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          height: 50px;
        }
      }

      &-position {
        font-size: 20px;
        color: $secondary-color;
        text-align: center;
        padding-bottom: 0.4em;
      }
    }
  }
}

.job-card {
  opacity: 0;
  animation-iteration-count: infinite;
}

@keyframes anim-in {
  from {
    transform: translateX(60px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
