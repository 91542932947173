$primary_color: #1a3aee;
// $primary_color: #444;
$secondary_color: #eece1a;
$dark_background: rgba(169, 169, 169, 0.582);
$show-home-image: true;
$home-image: url("img/background.jpg");
$background-opacity: 0.9;

@mixin easeOut {
  transition: all 0.5s ease-out;
}

@mixin easeIn {
  transition: all 0.5s ease-in;
}

// Media Query Mixins
@mixin mediaSm {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}

@mixin mediaXl {
  @media screen and (min-width: 1171px) {
    @content;
  }
}

@mixin background {
  @if $show-home-image {
    background: darken(rgba($primary_color, $background-opacity), 30);

    &:after {
      content: "";
      background: $home-image;
      background-size: cover;
      z-index: -1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      min-height: 100%;
      min-width: 1024px;
    }
  }
}

// Set Text Color
@function set-text-color($color) {
  @if (lightness($color) > 60) {
    @return #000;
  } @else {
    @return #fff;
  }
}

@import "stylesheets/components/about";
@import "stylesheets/components/mobileheader";
@import "stylesheets/components/navheader";
@import "stylesheets/components/main";
@import "stylesheets/components/projects";
@import "stylesheets/components/body";
@import "stylesheets/components/icons";

* {
  box-sizing: border-box;
}
body > #root > div {
  min-height: 100vh;
  height: 100%;
}
#project-root {
  @include background;
  color: set-text-color($primary-color);
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: 400;

  &.lg-heading {
    font-size: 6rem;
    margin-bottom: 0.5rem;
  }

  &.sm-heading {
    font-size: 2rem;
    padding: 0.2rem 1rem;
    background: rba(lighten($primary_color, 2), 0.5);

    &-sec {
      color: $secondary_color;
      font-size: 2rem;
      padding: 0.2rem 1rem;
    }
  }
}

a {
  color: set-text-color($primary-color);
  text-decoration: none;
}

.text-secondary-color {
  color: $secondary_color;
}

@import "stylesheets/components/mobile";
