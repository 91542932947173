@include mediaXl {
}

// Desktops & Laptops
@include mediaLg {
  #nav-header {
    height: 60px;
    .menu-nav {
      .nav-link {
        font-size: 20px;
        margin-right: 20px;
      }
    }
  }
}

// Tablets & Small Laptops
@include mediaMd {
  .main {
    h1.lg-heading {
      font-size: 60px;
      line-height: 1;
      margin-bottom: 1rem;
    }

    .sm-heading {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 1rem;
    }

    align-items: center;
    text-align: center;

    .tech-stacks {
      line-height: 0.75;
      .stack {
        display: block;
        width: 100%;
      }
      .dot {
        visibility: hidden;
      }
    }
  }

  .header {
    .menu {
      position: static;

      &-branding,
      &-nav {
        float: none;
        width: 100%;
        min-height: 0;
        flex-direction: column;
      }

      &-branding {
        height: 25vh;
      }
      &-nav {
        padding-bottom: 20vh;
        height: 75vh;
      }

      .portrait {
        width: 150px;
        height: 150px;
      }
    }
  }

  #about {
    padding: 2rem 2rem 2rem 2rem;

    h1.lg-heading {
      font-size: 60px;
      margin-top: 60px;
    }

    h2.sm-heading {
      padding-left: 0px;
    }

    .about-info {
      .job {
        &-card {
          display: block;
        }
        &-header {
          display: block;
          width: 230px;
          margin: auto;
          margin-top: 15px;
        }
        &-content {
          border: none;
          .job-points {
          }
          .job-technologies {
            // padding-top: 10px;
            border-top: $secondary-color 3px solid;
          }
        }
        &-position {
          margin-bottom: 15px;
        }
      }
    }
  }

  #work {
    .lg-heading {
      font-size: 60px;
      line-height: 1;
      margin-bottom: 1rem;
    }

    .sm-heading {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 1rem;
      padding-left: 0px;
    }

    align-items: center;
    text-align: center;
  }
}

// Smartphones
@include mediaSm {
  #work {
    padding: 20px;

    h1.lg-heading {
      margin-top: 60px;
      text-align: center;
    }

    .sm-heading {
      text-align: center;
    }

    .projects {
      .card {
        max-width: 280px;
        min-width: 280px;
      }
      .project-technologies {
        .technology {
          display: block;
        }
      }
    }
  }

  .main {
    padding-left: 0px;
    padding-right: 0px;

    h1.lg-heading {
      font-size: 45px;
      // margin-top: 5vh;
    }

    .tech-stacks {
      .stack {
        font-size: 22px;
      }
    }
  }

  #about {
    h1.lg-heading {
      margin-top: 60px;
      text-align: center;
    }

    .sm-heading {
      text-align: center;
    }
  }

  .header {
    .menu {
      &-nav {
        padding-bottom: 0px;
      }
    }
  }

  .about-info {
    .bio > p {
      font-size: 1.2rem;
    }
  }
}
