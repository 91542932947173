#nav-header {
  background-color: darken($primary-color, 8);
  z-index: 4;
  width: 100%;
  height: 70px;
  position: fixed;

  .menu-nav {
    width: 100%;
    height: 100%;
    padding-left: 65px;

    .nav-link {
      display: inline-block;
      position: relative;
      font-size: 26px;
      text-transform: uppercase;
      padding: 1rem 0;
      font-weight: 500;
      text-decoration: none;
      margin-right: 40px;
      color: set-text-color($primary-color);
      @include easeOut;

      &:hover {
        color: $secondary-color;
        @include easeIn;
      }
      &.active {
        color: $secondary-color;
      }
    }
  }
}
