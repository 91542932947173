.icons {
  margin: 50px 1rem 90px 1rem;
  a {
    padding: 0.4rem;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    color: set-text-color($primary-color);
    &:hover {
      color: $secondary-color;
      @include easeOut();
    }
  }
}

#nav-header {
  .icons {
    margin-top: 1rem;
    float: right;
  }
}
