.header {
  padding: 5px;
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 60px;
  background-color: darken($primary-color, 8);

  .menu-btn {
    position: absolute;
    z-index: 3;
    left: 25px;
    top: 25px;
    @include easeOut;

    .btn-line {
      width: 28px;
      height: 3px;
      margin: 0 0 5px 0;
      background: set-text-color($primary-color);
      @include easeOut;
    }

    &.menu-true {
      transform: rotate(270deg);
      .btn-line {
        &:nth-child(1) {
          transform: rotate(45deg) translate(5px, 5px);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translate(7px, -6px);
        }
      }
    }
  }

  .menu {
    position: fixed;
    top: 0;
    width: 100%;
    opacity: 0.9;

    &-branding,
    &-nav {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100vh;
      overflow: hidden;
    }

    &-nav {
      margin: 0;
      padding: 0;
      background: darken($primary-color, 5);
      list-style: none;
      float: right;

      .nav-item {
        display: inline-block;
        position: relative;
        font-size: 24px;
        text-transform: uppercase;
        padding: .4rem 0;
        font-weight: 500;
        text-decoration: none;

        .nav-link {
          padding: 0px;
          color: set-text-color($primary-color);
          &:hover {
            color: $secondary-color;
          }
        }

        .active {
          color: $secondary-color;
        }
      }
      .header-name {
        color: $secondary-color;
        font-size: 36px;
        border-bottom: 3px set-text-color($primary-color) solid;
      }
    }

    &-branding {
      background: $primary-color;
      float: left;
      .portrait {
        width: 250px;
        height: 250px;
        opacity: 1;
        border-radius: 50%;
        border: solid 3px $secondary-color;
        background-repeat: no-repeat;
        background-image: url("../../img/brian.jpg");
        background-size: cover;
        background-position: 0px -10px;
        
      }
    }
  }
}
